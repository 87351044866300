<template>
    <tr style="height: 40px">
        <td style="width: 15%">{{ item.nimike?.nimi }}</td>
        <td style="width: 5%">
            <template v-if="edited">
                <el-input-number :min="0" class="number-input-mini" size="small" v-model="item.maara"></el-input-number>
            </template>
            <span v-else v-html="item.maara" />
        </td>
        <td style="width: 35%">
            <template v-if="edited">
                <el-input v-model="item.tasmennys" type="textarea" autosize style="width: 100%"></el-input>
            </template>
            <span v-else v-html="item.tasmennys" />
        </td>
        <td style="width: 35%">
            <template v-if="edited">
                <el-select
                    multiple
                    v-model="item.ominaisuudet"
                    placeholder="Valitse ominaisuudet"
                    size="small"
                    style="width: 80%"
                    value-key="id">
                    <el-option
                        v-for="(ominaisuus, index) in ominaisuudet"
                        :key="index"
                        :label="ominaisuus.nimi"
                        :value="ominaisuus"></el-option>
                </el-select>
            </template>
            <template v-else-if="item.ominaisuudet">
                <el-tag
                    type="info"
                    size="small"
                    style="margin-right: 2px"
                    v-for="(ominaisuus, index) in item.ominaisuudet"
                    :key="index">
                    <span v-html="ominaisuus.nimi" />
                </el-tag>
            </template>
        </td>
        <td style="padding-right: 20px">
            <div style="width: 100px">
                <template v-if="edited">
                    <ComSaveButton @click="update"></ComSaveButton>
                    <ComCancelIconButton @click="cancel"></ComCancelIconButton>
                </template>
                <template v-else-if="kirjoitus">
                    <ComEditButton @click="edit"></ComEditButton>
                    <ComDeleteButton @click="del"></ComDeleteButton>
                    <template v-if="!item.nimike?.kaytossa === true">
                        <el-button text @click.prevent="" size="small" circle>
                            <ComTooltip content="Nimike on poistettu nimikkeistöstä. Korvaa nimike toisella.">
                                <el-icon :size="20">
                                    <Warning />
                                </el-icon>
                            </ComTooltip>
                        </el-button>
                    </template>
                </template>
            </div>
        </td>
    </tr>
</template>

<script setup lang="ts">
import { HuonekorttiNimikeResponse } from "@/api/models/huonekortti-nimike-response"
import { useNimikeUpdate, useNimikeDelete } from "@/methods/huonekorttiComposablses"
import { useNimikkeetData } from "@/methods/nimikkeetComposables"
import { useCloned } from "@vueuse/core"
import { computed, PropType, ref, toRef, watch } from "vue"
import { Warning } from "@element-plus/icons-vue"
import ComDeleteButton from "../common/buttons/ComDeleteButton.vue"
import ComEditButton from "../common/buttons/ComEditButton.vue"
import ComTooltip from "../common/ComTooltip.vue"
import ComCancelIconButton from "../common/buttons/ComCancelIconButton.vue"
import ComSaveButton from "../common/buttons/ComSaveButton.vue"
import { HuonekorttiNimikeRequestPut } from "@/api/models/huonekortti-nimike-request-put"
import { ElMessage, ElMessageBox } from "element-plus"
import { huonekorttiPage } from "@/views/huonekortti"
import { useSuunnittelutiedot } from "./suunnittelutiedot"
import { OminaisuusResponse } from "@/api/models/ominaisuus-response"

const props = defineProps({
    nimike: {
        type: Object as PropType<HuonekorttiNimikeResponse>,
        required: true,
    },
}) 

const { kirjoitus } = huonekorttiPage()
const { cancelEdit, setEdit } = useSuunnittelutiedot()
const { error: updateError, loading: updateLoading, response: updateResonse, updateNimike } = useNimikeUpdate()
const { error: deleteError, loading: deleteLoading, response: deleteResponse, deleteNimike } = useNimikeDelete()
const { cloned: item, sync } = useCloned(props.nimike)
const { getNimikeById } = useNimikkeetData()
const nimike = getNimikeById(toRef(props.nimike.nimike?.id))
const ominaisuudet = computed(() => nimike.value?.ominaisuudet ?? [])
const edited = ref<boolean>(false)

const edit = () => {
    edited.value = !edited.value

    setEdit(props.nimike.id)
}
const cancel = () => {
    edited.value = false

    sync()
    cancelEdit(props.nimike.id)
}
const update = async () => {
    const request = {
        maara: item.value.maara,
        tasmennys: item.value.tasmennys,
        ominaisuudet: item.value.ominaisuudet?.map((ominaisuus: OminaisuusResponse) => {
            return { id: ominaisuus.id }
        }),
        nimikeId: item.value.nimike?.id,
    } as HuonekorttiNimikeRequestPut

    await updateNimike(item.value.id, request) 
}
const del = async () => {
    ElMessageBox.confirm("Haluatko varmasti poistaa nimikkeen: " + item.value.nimike?.nimi).then(async () => {
        await deleteNimike(item.value.id)
    })
}

watch(updateLoading, (value) => {
    if (!value && updateResonse.value) {
        edited.value = false

        ElMessage.success("Nimikkeen päivitetty")
    }
})
watch(updateError, (value) => {
    if (value) {
        ElMessage.error("Nimikkeen päivitys epäonnistui")
    }
})
watch(deleteLoading, (value) => {
    if (!value && deleteResponse.value) {
        edited.value = false

        ElMessage.success("Nimikkeen poistettu")
    }
})
watch(deleteError, (value) => {
    if (value) {
        ElMessage.error("Nimikkeen poisto epäonnistui")
    }
})

</script>
