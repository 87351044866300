<template>
    <el-table :data="huonekortit" @sort-change="sortChanged" ref="tableRef" :scrollbar-always-on="true">
        <el-table-column label="Tilanimike" width="180" sortable="custom" prop="tilanimike">
            <template #default="scope">
                <template v-if="edit === scope.row.id">
                    <el-select size="small" v-model="editedHuonekortti.tilanimike.id">
                        <el-option
                            v-for="tilanimike in tilanimikkeet"
                            :key="tilanimike.id"
                            :label="tilanimike.nimi"
                            :value="tilanimike.id"></el-option>
                    </el-select>
                </template>
                <template v-else>
                    <router-link
                        :to="{ name: 'Huonekortti', params: { huonekorttiId: scope.row.id } }"
                        v-slot="{ href, navigate }"
                        custom>
                        <el-link type="primary" :href="href" @click="navigate">{{ scope.row.tilanimike.nimi }}</el-link>
                    </router-link>
                    <el-icon v-show="scope.row.liitteet.length">
                        <Paperclip></Paperclip>
                    </el-icon>
                </template>
            </template>
        </el-table-column>
        <el-table-column min-width="250px" label="Täsmennys" show-overflow-tooltip sortable="custom" prop="tasmennys">
            <template #default="scope">
                <template v-if="edit === scope.row.id">
                    <el-input size="small" v-model="editedHuonekortti.toiminta"></el-input>
                </template>
                <template v-else>
                    {{ scope.row.toiminta }}
                </template>
            </template>
        </el-table-column>
        <el-table-column label="Status" width="120" sortable="custom" prop="status">
            <template #default="scope">
                <ComStatus :huonekortti="scope.row" v-model="scope.row.status"></ComStatus>
            </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="Vastuuyksikkö" sortable="custom" prop="vastuuyksikko" width="180">
            <template #default="scope">
                <template v-if="edit === scope.row.id">
                    <el-select v-if="writableVastuuyksikot" size="small" v-model="editedHuonekortti.vastuuyksikko.id">
                        <el-option
                            v-for="vastuuyksikko in writableVastuuyksikot"
                            :key="vastuuyksikko.id"
                            :label="vastuuyksikko.nimi"
                            :value="vastuuyksikko.id"></el-option>
                    </el-select>
                </template>
                <template v-else>
                    {{ scope.row.vastuuyksikko.nimi }}
                </template>
            </template>
        </el-table-column>
        <el-table-column 
            prop="tilat" 
            label="Tilat" 
            show-overflow-tooltip
            min-width="300"
            >
            <template #default="scope">
                <template v-if="edit === scope.row.id">
                    <el-row>
                        <el-space :size="40">
                            <label>Tilatunnus</label>
                            <label>Pinta-ala</label>
                        </el-space>
                    </el-row>
                    <el-space direction="vertical">
                        <template v-for="(tila, index) in editedHuonekortti.tilat" :key="tila.id">
                            <el-row>
                                <el-space>
                                    <el-input v-model="tila.tilatunnus" size="small"></el-input>
                                    <el-input
                                        v-model="tila.pintaAla"
                                        size="small"
                                        type="number"
                                        placeholder="m2"></el-input>
                                    <div style="width: 60px">
                                        <ComMinusButton @click="removeTila(editedHuonekortti, index)" />
                                        <ComPlusButton
                                            v-if="index + 1 === editedHuonekortti.tilat?.length"
                                            @click="addTila(editedHuonekortti)" />
                                    </div>
                                </el-space>
                            </el-row>
                        </template>
                    </el-space>
                </template>
                <template v-else>
                    {{ formatTilat(scope.row.tilat) }}
                </template>
            </template>
        </el-table-column>
        <el-table-column label="lkm" width="100">
            <template #default="scope">{{ scope.row.tilat.length }} kpl</template>
        </el-table-column>
        <el-table-column align="right" width="100" fixed="right">
            <template #default="scope">
                <template v-if="edit === scope.row.id">
                    <ComCancelIconButton @click="cancelEdit()"></ComCancelIconButton>
                    <ComSaveButton @click="saveEdit(scope.row)"></ComSaveButton>
                </template>
                <el-badge v-if="!edit" is-dot :hidden="!scope.row.kommentit.length" class="badge">
                    <ComKommentointi :paakayttaja="paakayttaja" :viite="scope.row.id" :tyyppi="1" />
                </el-badge>
                <template v-if="!edit && writableVastuuyksikot?.some((v) => v.id === scope.row.vastuuyksikko.id)">
                    <el-dropdown>
                        <ComMoreButton @click="more = scope.row.id" />
                        <template #dropdown v-if="more == scope.row.id">
                            <el-dropdown-item @click="editHuonekortti(scope.row)">Muokkaa</el-dropdown-item>
                            <el-dropdown-item v-if="checkPoistoOikeus(scope.row)">
                                <ComHuonekorttiDeleteButton :huonekortti="scope.row" />
                            </el-dropdown-item>
                            <el-dropdown-item v-if="paakayttaja" @click="addMallihuonekorttiClick(scope.row)">
                                Luo mallihuonekortti
                            </el-dropdown-item>
                        </template>
                    </el-dropdown>
                </template>
            </template>
        </el-table-column>
    </el-table>
    <ComMallihuonekorttiEditToimintaDialog
        v-if="mallihuonekortti"
        v-model:dialog="mallihuonekorttiDialog"
        :huonekortti="mallihuonekortti" />
</template>

<script setup lang="ts">
import ComCancelIconButton from "@/components/common/buttons/ComCancelIconButton.vue"
import ComMinusButton from "@/components/common/buttons/ComMinusButton.vue"
import ComPlusButton from "@/components/common/buttons/ComPlusButton.vue"
import ComSaveButton from "@/components/common/buttons/ComSaveButton.vue"
import ComKommentointi from "@/components/kommentointi/ComKommentointi.vue"
import ComHuonekorttiDeleteButton from "./ComHuonekorttiDeleteButton.vue"
import ComStatus from "../ComStatus.vue"
import { Paperclip } from "@element-plus/icons-vue"
import ComMoreButton from "@/components/common/buttons/ComMoreButton.vue"
import ComMallihuonekorttiEditToimintaDialog from "@/components/hanke/huonekortit/table/ComMallihuonekorttiEditToimintaDialog.vue"
import { huonekorttiTableEditable } from "./huonekorttiTableEditable"

const {
    huonekortit,
    mallihuonekortti,
    mallihuonekorttiDialog,
    tilanimikkeet,
    edit,
    editedHuonekortti,
    paakayttaja,
    writableVastuuyksikot,
    more,
    tableRef,
    sortChanged,
    removeTila,
    addTila,
    cancelEdit,
    saveEdit,
    formatTilat,
    editHuonekortti,
    addMallihuonekorttiClick,
    checkPoistoOikeus,
} = huonekorttiTableEditable()
</script>

<style scoped>
.badge {
    margin-right: 12px;
}
.badge :deep(sup) {
    top: 4px;
}
</style>
